import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import ConfirmLocation from './ConfirmLocation.js';
import MyClass from './MyClass.js';
import MyLocationClass from './MyLocationClass.js';
import OftenVisitLocationClass from './OftenVisitLocationClass.js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loading from '../Loading'; // 로딩 컴포넌트 임포트
import LoadableComponent from '../LoadableComponent'; // 로딩 관리 컴포넌트 임포트

function LocationMain() {
    const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
    const {lo} = useParams();
    const [data, setData] = useState({ mainClass: [], subClass: [], myClass: [] });
    const [myUid, setMyUid] = useState('');
    
    const [dataLoaded, setDataLoaded] = useState(false); // 데이터 로딩 상태
    const [confirmLocationLoaded, setConfirmLocationLoaded] = useState(false);
    const [myClassLoaded, setMyClassLoaded] = useState(false);
    const [myLocationClassLoaded, setMyLocationClassLoaded] = useState(false);
    const [oftenVisitLocationClassLoaded, setOftenVisitLocationClassLoaded] = useState(false);
    const [allComponentsLoaded, setAllComponentsLoaded] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
	console.log('LocationMain lo: ', lo);
        const fetchData = async () => {
            try {
                const response = await axios.post(`${apiUrl}/viewClassAll`, { myToken: token });
                const fetchedData = {
                    mainClass: response.data.mainClass,
                    subClass: response.data.subClass,
                    myClass: response.data.myClass
                };
                setData(fetchedData);
                setMyUid(response.data.myUid);
                setDataLoaded(true); // 데이터 로드 완료
                console.log(fetchedData);
            } catch (error) {
                console.error('1-2 : Error fetching data: ', error);
            }
        };
        fetchData();
    }, [apiUrl]);

    useEffect(() => {
        // 모든 컴포넌트가 로딩되었는지 확인
        setAllComponentsLoaded(
            dataLoaded && confirmLocationLoaded && myClassLoaded && myLocationClassLoaded && oftenVisitLocationClassLoaded
        );
    }, [dataLoaded, confirmLocationLoaded, myClassLoaded, myLocationClassLoaded, oftenVisitLocationClassLoaded]);

    return (
        <div>
            {!allComponentsLoaded && <Loading loading={!allComponentsLoaded} />} {/* 로딩 중 표시 */}
            <Container fluid="md" style={{ textAlign: 'left' }}>
                <LoadableComponent Component={ConfirmLocation} onLoad={() => setConfirmLocationLoaded(true)} paramslo={lo} />
                <LoadableComponent Component={MyClass} onLoad={() => setMyClassLoaded(true)} myClass={data.myClass} myUid={myUid} />
                <LoadableComponent Component={MyLocationClass} onLoad={() => setMyLocationClassLoaded(true)} mainClass={data.mainClass} myUid={myUid} />
                <LoadableComponent Component={OftenVisitLocationClass} onLoad={() => setOftenVisitLocationClassLoaded(true)} subClass={data.subClass} myUid={myUid} />
            </Container>
            <br /><br /><br />
        </div>
    );
}

export default LocationMain;


