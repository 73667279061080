import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, Modal } from 'react-bootstrap';
import {ArrowRepeat} from 'react-bootstrap-icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

function ChangeRoom ({rid, changeRoom, setIsCancelButtonVisible}) {

    const { lo } = useParams();
    const [chatRoomId, setChatRoomId] = useState(lo);
    const [btnRefresh, setBtnRefresh] = useState(false);
    const handleRefresh = () => {
	setBtnRefresh(!btnRefresh);
    };

    const [chatRoomList, setChatRoomList] = useState([]);
    useEffect(() => {

	const fetchData = async () => {
	    const response = await axios.post(`${apiUrl}/ChatRoomList`,{ myToken: token, rid: rid });
	    console.log('chatRoomList: ', response.data);
	    setChatRoomList(response.data);
	};

	fetchData();

    },[rid, btnRefresh]);
    const [selectedRoom, setSelectedRoom] = useState(null);

    const handleRoomClick = (roomId) => {
	    setSelectedRoom(roomId);
    };

    const handleCancelButtonClick = () => {
	    setIsCancelButtonVisible(!changeRoom);
    };

    const roomInfoList = chatRoomList.map((room) => {
	    const isCurrentRoom = room.rid === rid;

	    return (
		<ListGroup.Item
		    key={room.rid}
		    active={selectedRoom === room.rid}
		    disabled={room.rid === rid}
		    onClick={() => handleRoomClick(room.rid)}
		    style={
			    isCurrentRoom
			    ? { backgroundColor: 'lightgray', color: 'gray', cursor: 'not-allowed' } // 현재 소속된 채팅방일 경우 스타일 변경
			    : selectedRoom === room.rid
			    ? { backgroundColor: '#ffca5a', color: 'white' }
			    : {}
		    }
		>
		    <Row>
		        <Col xs={6}>{room.roomName}</Col>
		        <Col xs={3}>{room.memberCount}</Col>
		        <Col xs={3}>{50-room.memberCount}</Col>
		    </Row>
		</ListGroup.Item>
	    );
    });

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [redirectToChatting, setRedirectToChatting] = useState(false);

    const changeRoomInfo = async() => {
	console.log('selectedRoom: ', selectedRoom);
	const response = await axios.post(`${apiUrl}/ChangeRoomInfo`, { myToken: token, selectedRoom, nowRid: chatRoomId });
	console.log('response Data Here***: ', response);
        if (response.data === 'success') {
            setModalMessage('채팅방을 이동했어요.');
            setRedirectToChatting(true);
            setShowModal(true);
        } else if (response.data === 'NotEnoughTime') {
            setModalMessage('채팅방 변경은 일주일에 한번만 가능해요.');
            setRedirectToChatting(false);
            setShowModal(true);
        } else if (response.data === 'fail') {
            setModalMessage('채팅방 변경에 실패했어요.');
            setRedirectToChatting(false);
            setShowModal(true);
        } else if (response.data === 'same'){
            setModalMessage('동일한 채팅방이에요.');
            setRedirectToChatting(false);
            setShowModal(true);
	} 
    };

    const handleClose = () => {
	setShowModal(false);
	    if (redirectToChatting) {
		window.location.href = '/chatting';
	    }
    };

    const currentRoom = chatRoomList.find(room => room.rid === rid);
    const currentRoomName = currentRoom ? currentRoom.roomName : '';

    return(
    <>
      <Modal show={showModal} onHide={handleClose} centered>
	    <Modal.Body style={{fontSize: '0.8rem'}}>{modalMessage}</Modal.Body>
	    <Modal.Footer style={{display:'block', textAlign:'center'}}>
	        <Button variant="warning" onClick={handleClose} style={{fontSize: '0.8rem'}}>
	    	    확인
	        </Button>
	    </Modal.Footer>
      </Modal>
      <Container style={{width: '100%', height: '100vh', position: 'fixed', zIndex: 1001, backgroundColor: 'white'}}>
        <Row className="justify-content-center" style={{marginBottom: '0.5rem', marginTop: '1rem'}}>
          <Col xs="auto">
            <Row><b style={{fontSize: '1rem'}}>현재 위치</b></Row>
            <Row><b style={{color: '#ff981b'}}>{currentRoomName && currentRoomName}</b></Row>
          </Col>
        </Row>
        <Row>
          <Col xs={10} style={{textAlign:'right'}}>
            <p>채팅방은 같은 연령대 내에서 변경 가능합니다.</p>
          </Col>
	  <Col xs={2} style={{textAlign:'left'}}>
	    <ArrowRepeat style={{fontSize:'1.2rem'}} onClick={handleRefresh} />
	  </Col>
        </Row>
        <ListGroup>
	    <ListGroup.Item>
		<Row>
	    	    <Col xs={6}> <b>채팅방 제목</b> </Col>
	    	    <Col xs={3}> <b>현재인원</b> </Col>
	    	    <Col xs={3}> <b>남은 자리</b> </Col>
	    	</Row>
	    </ListGroup.Item>
          { roomInfoList && roomInfoList}
        </ListGroup>
        <Row className="justify-content-center" style={{ marginTop: '10px' }}>
          <Col>
            <Button variant="warning" style={{fontSize:'0.8rem', width: '100%'}} onClick={()=>changeRoomInfo()} >변경하기</Button>
          </Col>
	  <Col>
	    <Button variant="light" style={{fontSize: '0.8rem', width: '100%'}} onClick={handleCancelButtonClick}> 취소하기</Button>
	  </Col>
        </Row>
      </Container>
    </>
    );

}

export default ChangeRoom;
