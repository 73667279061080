import React, {useState, useEffect} from 'react';
import {Container, Form, InputGroup} from 'react-bootstrap';
import {Row, Col, Button, Image,Ratio} from 'react-bootstrap';
import {CaretRight} from 'react-bootstrap-icons';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const width100={
	width: '100%',
	fontSize: '0.8rem'
};
const textSize={
	fontSize: '0.8rem'
};

function MyProfile() {
	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};

	const token = localStorage.getItem('token');
        const [myProfileInfo, setData] = useState(null);
	const [myNickName, setNickName] = useState(null);
	const [myMessage, setMyMessage] = useState(null);
        useEffect(()=>{
                const fetchData = async() => {
                        const response = await axios.post(`${apiUrl}/MyProfile`,{ myToken: token }); 
                        await setData(response.data);
			await setNickName(response.data.loginUser.nname);
			await setMyMessage(response.data.loginUser.message);
                };  
                fetchData();
        },[]);

	const handleMyNickName = (event) => {
		setNickName(event.target.value);
	};
	const handleMyMessage = (event) => {
		setMyMessage(event.target.value);
	};

	const [fixImageMessage, setFixImageMessage] = useState(null);	
	const [fixMessage, setFixMessage] = useState(null);
	//이미지 저장하고있는 함수
	const [content, setContent] = useState('');
	//이미지 저장하고 임시로 보여주는 변수
	const [showProfile, setShowProfile] = useState(null);

	const onChange = async(e) => {
		const file = e.target.files[0];

		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 800,
			useWebWorker: true
		};
		try{
		    const compressedFile = await imageCompression(file,options);
		    const imageUrl = URL.createObjectURL(compressedFile);
		    setShowProfile(imageUrl);
		    setContent(file);
		}catch(error){
			console.error('이미지 압축 또는 업로드 실패: ', error);
			setFixImageMessage('이미지 압축 또는 업로드 실패');
		}
	};
	//이미지 업로드 함수
	const handleUpload = async() => {
		const formData = new FormData();
		formData.append("img", content);
		formData.append('myToken', token);
		try{
		    const response = await axios.post(`${apiUrl}/FixMyProfileImage`, formData, {
			headers: {
			    'Content-Type': 'multipart/form-data', // 필수: 이미지를 포함한 FormData를 전송할 때는 반드시 이 헤더를 설정해야 합니다.
			}
		    });
		    localStorage.setItem('token', response.data);
		}catch (error) {
			console.error(error);
		}
	};


	//프로필 닉네임과 메시지 업데이트
	const profileUpdate = async() => {
		const newNickname = document.getElementById('FixNickname').value;
		const newMessage = document.getElementById('FixMessage').value;
		//myToken이 있을 경우에 Image 파일 업로드 진행
		if(token){
			if(showProfile){
				try{
					handleUpload();
					setFixImageMessage('이미지가 업로드 성공');
				}catch(error){
					console.error('이미지 업로드 실패 : ', error);
				}
			}
			//닉네임이 공백인지 체크
			if(!newNickname){
				setFixMessage('닉네임을 설정해주세요');
			}else if(newNickname===""){
				setFixMessage('닉네임을 설정해주세요2');
			}else{
				//Default 실행
				try{
					//업데이트 요청전달 및 JWT Token 재설정
				const response = await axios.post(`${apiUrl}/FixMyProfile`, {nname: newNickname, message: newMessage, myToken: token});
					localStorage.setItem('token', response.data.token);
					setFixMessage('업데이트 성공');
				}catch(error){
					console.error('업데이트 실패: ', error);
				}
			}
		}
		//File Upload Area 
		//react 단에서 이미지를 저장

	};


	return (
	<div>
	    <h3><b>내 프로필 수정</b></h3>
		<Container style={{textAlign: 'left'}}>
	    <Row>
	      <Col></Col>
	      <div style={{ cursor: 'pointer', textAlign:'center'}} onClick={() => document.getElementById('fileInput').click()}>
	      <Col xs={7}style={{padding: "0", display:'inline-block'}}>
		    <Ratio aspectRatio="1x1">
			<Image src={showProfile ? showProfile: (myProfileInfo && (myProfileInfo.loginUser.pimage))} style={{border: 'solid 1px #c0b9b9'}} roundedCircle fluid />
		    </Ratio>
	      </Col>
	      </div>
		<input id="fileInput" type="file" accept="image/*" onChange={onChange} style={{ display: 'none' }} />
	      <Col></Col>
	    </Row>
	  </Container>
	  <div className="text-center">
		<hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
	  </div>
	<div style={{textAlign: 'center'}}>
	  <div style={{display:'inline-block', width:'80%'}}>
	  <Row>
		<InputGroup className="mb-3">
		    <InputGroup.Text style={textSize}>
			닉네임
		    </InputGroup.Text>
		    <Form.Control aria-label="Default" aria-describedby="inputGroup-sizing-default" id="FixNickname" value={myNickName && (myNickName)} onChange={handleMyNickName} style={textSize}/>
		</InputGroup>
	  </Row>
	  <Row>
		<InputGroup className="mb-3">
		    <InputGroup.Text style={textSize}>
			메시지
		    </InputGroup.Text>
		    <Form.Control aria-label="Default" aria-describedby="inputGroup-sizing-default" id="FixMessage" value={myMessage && (myMessage)} onChange={handleMyMessage} style={textSize}/>
		</InputGroup>
	  </Row>
	  </div>
	</div>
	  <Row className="mt-0">
		<Col style={{color: 'orange', fontWeight: 'bold'}}>{fixImageMessage && (fixImageMessage)}</Col>
	  </Row>

	  <Row className="mt-0">
		<Col style={{color: 'orange', fontWeight: 'bold'}}>{fixMessage && (fixMessage)}</Col>
	  </Row>
	<div style={{textAlign: 'center'}}>
	  <div style={{display:'inline-block', width:'80%'}}>
	  <Row className="mt-3">
		<Col><Button variant="warning" style={width100} onClick={profileUpdate}>수정</Button></Col>
		<Col><Button variant="secondary" onClick={goBack} style={width100}>닫기</Button></Col>
	  </Row>
	  </div>
	</div>

	</div>
	);
}

export default MyProfile;
