import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Image, Ratio, Container} from 'react-bootstrap';
import {ChevronCompactRight} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import axios from 'axios';


function MyProfile() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

	//데이터 받아오기 JWT 복호화 데이터
        const [myProfileInfo, setData] = useState(null);
        useEffect(()=>{
	        const fetchData = async() => {
			const token = localStorage.getItem('token');
			console.log(token);
			const response = await axios.post(`${apiUrl}/MyProfile`,{ myToken: token });
                        await setData(response.data);
                        await console.log(response.data);
                };  
                fetchData();
        },[]);

	return (
	<div>
	    <h3><b>내 프로필</b></h3>
		<Container style={{textAlign: 'left'}}>
	    <Row>
		{myProfileInfo && (
			<>
			<Link to='/profile' style={{textDecoration: 'none', color: 'inherit', display: 'inline-flex', padding:0}}>
			    <Col xs={2} className={""} style={{padding: "0", width:'14%'}}>
				<Ratio aspectRatio="1x1">
				<Image src={myProfileInfo.loginUser.pimage} roundedCircle fluid />
				</Ratio>
				</Col>
			      <Col xs={7} style={{paddingLeft: '20px'}}>
				<Row><b>{myProfileInfo.loginUser.nname}</b></Row>
				<Row><p>{myProfileInfo.loginUser.message}</p></Row>
			      </Col>
			      <Col xs={3} style={{textAlign: 'right'}}>
				  <ChevronCompactRight />
			      </Col>
			</Link>
			</>
		)}
	    </Row>
	  </Container>
	  <div className="text-center">
		<hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
	  </div>
	</div>
	);
}

export default MyProfile;
