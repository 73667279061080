import {useState, useEffect} from 'react';
import {Container, Row, Col, Button, Image, Ratio} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {PinAngle} from 'react-bootstrap-icons';
import OpenClass from './OpenClass.js';
import ClassView from './ClassView.js';

const fontSizeSmall={fontSize: '0.9rem', width: '100%', margin:'0', padding: '3px', textAlign:'center'};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const classBody = {backgroundColor: '#efefef', marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '0'};
const classAll = {textAlign: 'center', marginRight: '1px', marginLeft:'1px', width:'33%'};
const fontSmall = {fontSize:'0.6rem', padding: '3px'};
const marginTop2 = {marginTop: '2px'};

const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedMonth}월 ${formattedDay}일 ${formattedHours}:${formattedMinutes}`;
};


function checkType(MyType){
	let category = 0;

	switch(MyType) {
		case 'Amity': category = "/ClassAmity.png"; break;
		case 'Exercise': category = "/ClassExercise.png"; break;
		case 'Hobby': category = "/ClassHobby.png"; break;
		case 'Etc': category = "/ClassEtc.png"; break;
		default: category = "/ClassEtc.png"; break;
	}
	return category;
}



function MyClass({myClass, myUid}) {


	//##############데이터 세팅하는 부분################
        //최근 3개의 데이터 돌려서 추출하기
        const [myClassData, setMyClassData] = useState([]);            
        useEffect(() => {
                if(myClass && myClass.length >0){
                        setMyClassData(myClass);
			console.log('myClass.js : ',myClass);
                }
        },[myClass]);


	//Class 정보 정리
	const ViewMyClassList = myClass.map((v, index)=> (
		<Col key={index} style={classAll}>
		    <div className="text-center" style={fontSmall}>
		    </div>
		    <Row>
			<Row>
			</Row>
			<Row style={classBody}>
			    {
				    myUid === v.cOpenUser 
				    ? <div style={{position: 'relative'}}>
				    	<img
				    	    src='/crown.png'
				    	    alt='Crown Icon'
				    	    style={{position:'absolute', right:'5px', top:'5px', zIndex: 1, width: '15px', height: '15px'}}
				    	/>
				    </div> 
				    : null
			    }
			    <Ratio aspectRatio="1x1" style={marginTop2}>
				<Image src={checkType(v.ctype)} fluid rounded style={{padding: '5px 15px'}}/>
			    </Ratio>
			    <div style={{fontWeight:'bold'}}>{v.cTitle}</div>
			    <div>{formatDate(v.cOpenDate)}</div>
			    <div>참여인원: ({v.cNowUser.length+1}/{v.cTotalNum})</div>
			</Row>
			<Row style={buttonSpace0}>
			    <Button variant="secondary" style={fontSizeSmall2} onClick={() => handleViewClick(v.classId)}>
				내용보기
			    </Button>
			</Row>
		    </Row>
		</Col>
	));
	//#############################################

	//######################클릭하면 보여주는 부분#################
	const [viewClassCheck, setViewClassCheck] = useState(false);
	const [viewClassId, setViewClassId] = useState(null);

	const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

	const handleCloseView = () => {
		setViewClassCheck(!viewClassCheck);
	};
	//############################################################



	return(
	<div>
	<Row style={{marginTop: '2.5rem'}}>
	    <Col xs={9}>
		<h3><b>나의 모임</b></h3>
	    </Col>
	    <Col xs={3} style={{fontSize: '0.7rem'}}>
		{ViewMyClassList.length > 0 ? (
		<Link style={{textDecoration: 'none'}} to="/myClass">
		    <Button variant="secondary" style={fontSizeSmall}>
			더보기
		    </Button>
		</Link>
		) : (
		    <Button variant="secondary" style={fontSizeSmall} disabled>
			더보기
		    </Button>
		)}
	    </Col>
	</Row>
	    <div>
		<Row style={fontSizeSmall}>
		    {ViewMyClassList.length > 0 ? (ViewMyClassList) : (
			    <div style={{paddingTop: '1rem'}}>
			    <Image src='/noClass.png' style={{maxWidth: '20%'}} />
			    <p style={{marginTop: '1rem'}}>모임을 만들어보세요</p>
			    </div>
			)}
		</Row>
	    </div>

	{viewClassCheck && (
		<div>
		    <ClassView classId={viewClassId} onCloseView={handleCloseView}/>
		</div>
	)}


	</div>
	);
}

export default MyClass;
